.rs-table.tableFilters__form {
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.tableFilters__form.rs-table.rs-table-hover .rs-table-row {
    background: none;
    border: none;
}

.tableFilters.rs-table.rs-table-hover .rs-table-row:last-child {
    border-bottom: none;
}

.tableFilters.tableFilters__form.rs-table-hover .rs-table-row:not(.rs-table-row-header),
.tableFilters.tableFilters__form.rs-table-hover .rs-table-row .rs-table-cell-group,
.tableFilters.tableFilters__form.rs-table-hover .rs-table-row .rs-table-cell {
    background: none;
}

.tableFilters .rs-btn-sm {
    margin-right: 22px;
    min-width: 28px;
    padding: 7px;
}

.tableFilters .rs-btn-sm .rs-icon {
    display: block;
}

.tableFilters .rs-form-control-wrapper > .rs-input-number,
.tableFilters .rs-form-control-wrapper > .rs-input {
    width: 100%;
}

.tableFilters_field {
    width: 100%;
}

.tableFilters .rs-form-control-wrapper .rs-error-message-placement-top-end .rs-error-message {
    bottom: auto;
    font-size: 10px;
    padding: 0 2px;
    border: none;
    box-shadow: none;
    right: 6px;
    top: -4px;
}

.tableFilters .rs-form-control-wrapper .rs-error-message-arrow,
.tableFilters .rs-form-control-wrapper .rs-error-message-arrow {
    display: none;
}

.tableFilters button.rs-btn-default.rs-btn-lightblue {
    background-color: #1E90FF;
    color: #ffffff;
}
.tableFilters button.rs-btn-default.red {
    background-color: #F8432F;
}
.tableFilters button.rs-btn-default.green {
    background-color: #20BA88;
}

.tableFilters__previewText {
    line-height: 36px;
}
.tableFilters__headerText {
    line-height: 37px;
}

.tableFilters_buttons {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
}

.mailingSettings .rs-form-group.disabled .rs-control-label {
    opacity: 0.5;
}

.rs-btn-default.rs-btn-green.tableFilters_submit {
    background-color: #20BA88;
}

.rs-btn-default.rs-btn-green.tableFilters_submit:not(.rs-btn-disabled):hover {
    background-color: #20CA88;
}