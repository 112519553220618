.rs-tree-node-children  .rs-tree-node-active > .rs-tree-node-label {
    font-weight: normal;
    color: #20BA88;
    border-left: 3px solid #20BA88;
    border-radius: 2px;
    background: inherit;
}
.rs-tree-node-children > .rs-tree-node-active > .rs-tree-node-label {
    font-weight: normal;
    color: inherit;
    border-left: none
}
.rs-tree-node-expand-icon-wrapper {
    width: 100%
}
.rs-tree-node > .rs-tree-node-label {
    padding: 0 !important;
}
.top-mark {
    position: absolute;
    top: -200px
}
.api_documentation {
    font-size: 1.3rem;
    font-weight: 600;
    color: var(--color-brand1);
}
.api_menu {
    border-radius: 5px;
}
.api_menu_item {
    user-select: none;
    padding: 8px 16px;
    width: 200px;
    display: block;
    color: inherit;
    cursor: pointer;
}
.api_menu_item-anchor {
    border: none;
    box-shadow: none !important;
}
.api_menu_item_anchor_item-drop {
    border-top: none;
    border-bottom: none;
    box-shadow: none !important;
    padding-left: 8px;
}

.api_menu_item_anchor_item-drop .api_menu_item_anchor_item_drop-item  {
    padding-top: 4px;
    padding-bottom: 4px;
}
.api_menu_item_anchor-item {
    min-width: 200px;
    padding: 5px !important;
    border: none;
    box-shadow: none !important;
    user-select: none !important;
}
.api_menu_item_anchor_item-link {
    color: var(--activate-bg) !important;
    font-size: 12px;
    text-decoration: none !important;
}
.api_menu_item_anchor_item-link.api_menu_item_anchor_item-nodrop {
    font-size: inherit;
}
.api_documentation-link {
    margin-top: 20px;
}
.api_documentation-link .rs-breadcrumb-separator {
    font-size: 1rem;
    margin: 0 10px 0 10px;
}
.api_documentation-link_item {
    color: #282c34 !important;
    font-size: 1rem;
    font-weight: 600;
}
.api_documentation-link_item_active {
    color: var(--color-primary) !important;
}
.api_documentation_list-parameters_list_item_wrapper {
    margin-bottom: 5px;
}
.api_documentation_list-parameters_list_item_wrapper_item {
    min-width: 95px;
    margin-left: 20px;
    flex-shrink: 1 !important;
    overflow: auto;
    display: inline-block;
}
.api_documentation_list-parameters_list_item_wrapper_item.header {
    font-style: normal;
    font-weight: 600;
    margin-left: 0;
}

.api_documentation-anchor {
    position: relative;
    width: 110px;
    float: right;
    right: 0;
}

.api_documentation_anchor-link {
    display: inline-block;
    position: absolute;
    padding: 10px;
    color: black !important;
    background: white;
    border-left: 2px solid var(--color-primary);
    border-bottom: 2px solid var(--color-primary);
    border-right: 2px solid var(--color-primary);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.api_documentation-request_header {
    margin-top: 30px;
    font-weight: 600;
    font-size: 14pt;
}
.api_documentation-request_http_method_header {
    font-weight: 600;
    margin-top: 10px;
}
.api_documentation-request_http_text {
    margin-left: 10px;
}
.api_documentation-request_body_header {
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
}
.api_documentation_list-methods_header {
    margin-top: 30px;
    font-weight: 600;
    font-size: 14pt;
}
.api_documentation_list {
    margin-top: 10px;
}
.api_documentation_list-group {
    margin: -10px 0 0 0;
    display: block;
    font-size: 1rem;
    opacity: 0.7;
    user-select: none;
}

.api_documentation_list-service {
    margin: -10px 0 0 0;
    display: block;
    font-size: 1rem;
    user-select: none;
    color: snow;
}

.api_documentation_list-service.voice {
    background-color: var(--color-violet);
    padding: 0px 8px;
    border-radius: 10px;
    margin-right: 5px;
}

.api_documentation_list-service.sms {
    background-color: var(--color-info);
    padding: 0px 8px;
    border-radius: 10px;
    margin-right: 5px;
}


@media (max-width: 767px) {
    .api_documentation_list-group {
        margin: 0;
        font-size: 18px;
        opacity: 1;
        text-align: left;
        width: 100%;
        color: #20BA88;
        font-weight: 500;
    }
}

.api_documentation_list-item {
    margin: 0 20px 0 20px;
    background: #F2F2F2 !important;
}
.api_documentation_list-item:not(:last-child) {
    border-bottom: 1px solid #E5E5EA;
}
.api_documentation_list-parameters_item {
    width: 100%
}
.api_documentation_list-parameters_header {
    font-weight: 600;
    margin-bottom: 10px;
    display: inline-block;
}
.api_documentation_list-method_http {
    margin: 10px 0 10px 0;
    font-weight: 600;
    font-style: italic;
    color: var(--color-grey);
}

@media (max-width: 767px) {

    .api_documentation_list-method_http {
        font-weight: 400;
        font-style: normal;
    }
}

.api_documentation_list-description_header {
    font-weight: 600;
    display: inline-block;
}
.api_documentation_list-description_text {
    margin-left: 5px;
    display: inline-block;
}

.api_documentation_list-description {
    margin: 10px 0 10px 0;
}
.api_documentation-api_link, .api_documentation_list-method_api_link {
    margin: 10px 0 10px 0;
    font-size: 1rem;
    overflow: auto;
}
.api_documentation_list-method_api_item.header {
    min-width: 95px;
    flex-shrink: 1 !important;
    display: inline-block;
    font-weight: 600;
}
.api_documentation_list-method_api_item.text {
    margin-left: 20px;
}
.api_documentation_list-method_api .api_documentation_list-method_api_item.text {
    font-weight: 600;
    color: var(--color-brand1)
}
.api_documentation_list-description .rs-flex-box-grid-item {
    flex: content;
}

@media (max-width: 767px) {

    .api_documentation_list-method_api_item {
        display: block;
    }

    .api_documentation_list-description .rs-flex-box-grid-item {
        flex-shrink: 1;
        
    }
}

.api_documentation_list-method_api_src {
    color: var(--color-primary);
    font-weight: 600;
}
.api_documentation_list .api_documentation_list-method_api_link_wrapper, .api_documentation-api_link_wrapper {
    margin-top: 5px;
    /* background: #d7ffdc !important; */
    background: #5f6b83 !important;
}
.api_documentation_list .api_documentation_list-method_api_link_wrapper .rs-message-container,
.api_documentation-api_link_wrapper .rs-message-container {
    padding: 10px;
}

.api_documentation-api_link_wrapper .rs-message-body {
    margin-top: 10px;
}

.api_documentation-api_link_wrapper .rs-message-body pre,
.api_documentation-api_link_wrapper .rs-message-body div {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    font-family: Consolas, Menlo, Monaco, "Lucida Console", "Liberation Mono", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Courier New", monospace, serif;
    /* color: orangered; */
    color: #E5E5EA;
    background-color: #4a5261;
    padding: 4px;
    border-radius: 4px;
}

.api_documentation_list .api_documentation_list-method_api_link_wrapper .api_documentation_list-method_api_link_wrapper_header,
.api_documentation-api_link_wrapper .api_documentation-api_link_wrapper_header {
    margin-right: 20px;
    color: #E5E5EA;
}
.api_documentation_list .api_documentation_list-method_api_link_wrapper .api_documentation_list-method_api_link_wrapper_icon,
.api_documentation-api_link_wrapper .api_documentation-api_link_wrapper_icon {
    top: -2px;
    position: relative;
    color: var(--color-primary);
    font-weight: 600;
}
.api_documentation_list .api_documentation_list-method_api_link_wrapper .api_documentation_list-method_api_link_wrapper_icon:hover,
.api_documentation-api_link_wrapper .api_documentation-api_link_wrapper_icon:hover {
    color: var(--color-green-light);
    cursor: pointer;
}
/* .api_documentation_list-parameters_list {
    margin-top: 10px;
}
.api_documentation-request .pretty-json-container.object-container .object-key,
.api_documentation_list-parameters_list .pretty-json-container.object-container .object-key {
    color: var(--color-secondary);
    font-weight: 600;
}
.api_documentation-request .pushed-content.object-container .object-key,
.api_documentation_list-parameters_list .pushed-content.object-container .object-key {
    color: #20B188 !important;
}
.api_documentation-request .pushed-content.object-container .variable-row .variable-value span,
.api_documentation_list-parameters_list .pushed-content.object-container .variable-row .variable-value span {
    color: var(--activate-bg) !important;
} */

.api_documentation_list_response-json {
    margin-top: 10px;
}
.api_documentation-request .pretty-json-container.object-container .object-key,
.api_documentation_list_response-json .pretty-json-container.object-container .object-key {
    color: var(--color-secondary);
    font-weight: 600;
}

.api_documentation_list_response-json .pretty-json-container.object-container > .object-content {
    padding: 5px;
    border: 2px dotted gainsboro;
    border-radius: 5px;
}

.api_documentation_list_response-json-list {

}

.api_documentation_list_response-json-list .api_documentation_list_response-json-item {
    margin-bottom: 15px;
}

.api_documentation_list-method_api_item.json .api_documentation_list_response_json-button {
    background-color: var(--color-main);
    color: white;
    font-weight: 600;
    margin: 5px 0;
}

.api_documentation_list-method_api_item.json .api_documentation_list_response_json-button > .rs-icon {
    margin-left: 4px;
}

.api_documentation-request .pushed-content.object-container .object-key,
.api_documentation_list_response-json .pushed-content.object-container .object-key {
    color: var(--color-main-hover) !important;
}
/* .api_documentation-request .pushed-content.object-container .variable-row .variable-value span,
.api_documentation_list_response-json .pushed-content.object-container .variable-row .variable-value span {
    color: var(--activate-bg) !important;
} */

.api_keys {
    margin-top: 40px;
}
.api_keys-list_item {
    color: var(--activate-bg);
    width: 260px;
}
.api_keys-list_item.active {
    color: #20B188;
    font-weight: 600;
}
.api_keys-list_item_icon {
    position: absolute;
    top: 2px;
    right: 5px;
    color: var(--color-grey);
}
.api_keys-list_item_icon.active {
    color: #20B188;
}
.api_keys-list_item:hover {
    background: #e3ffef;
    color: var(--color-secondary);
    cursor: grab;
}
.api_documentation-tooltip {
    color: var(--activate-bg);
    margin-left: 10px;
    top: 0;
}
.api_keys-panel {
    padding: 4px 32px
}
.api_keys-panel_button_icon {
    margin-left: 10px;
}
.api_keys-panel_button_generate {
    margin-left: 20px;
}
.api_keys-panel_icon {
    color: var(--color-secondary);
    font-weight: 600;
}
.api_keys-panel_icon:hover {
    color: #6c48a5;
    font-weight: 600;
}
.simple_code {
    background: #FFFFFF;
    color: #282c34;
    padding: 20px 0 0 40px;
}
.api_examples-python.api_examples-python_header {
    margin-top: 40px;
    font-size: 1rem;
}
.api_examples-python_header {
    font-weight: 600;
    margin: 20px 0 10px 0;
}
.api_examples-python_options_description {
}
.api_examples-python_options_code {
    margin: 0;
}
.api_examples-python_header_method {
    color: var(--color-primary)
}
.api_examples-python_header_method_description {

}