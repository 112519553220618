.allocated-wrapper {
    vertical-align: top;
    margin-bottom: auto;
    margin-top: 0;
    display: inline-block;
    width: 100%;
}

.allocated-wrapper .rs-picker-toggle-wrapper{
    width: 100%;
    min-width: 180px;
}

.no-margin {
    margin: 0 !important;
}
.allocation_result_warning-message {
    margin-bottom: 20px;
}