@charset "UTF-8";
.rtl.app-client {
    direction: rtl;
    text-align: right;
}

.rtl.app-client .rs-navbar-nav {
    float: right;
}

.rtl.app-client .rs-navbar-nav.rs-navbar-right {
    float: left;
}

.rtl.app-client .rs-navbar-header {
    float: right;
}

.rtl.app-client .rs-navbar-header .custom-dropdown-menu {
    margin-right: 0 !important;
    margin-left: 24px !important;
}

.rtl.app-client .rs-navbar-header .custom-dropdown-menu .custom-dropdown-menu-toggle {
    padding-right: 12px;
    padding-left: 32px;
}

.rtl.app-client .rs-navbar-header .custom-dropdown-menu .custom-dropdown-menu-toggle-text {
    text-align: right !important;
}

.rtl.app-client .rs-navbar-header .rs-dropdown .rs-btn > .rs-dropdown-toggle-caret {
    right: auto;
    left: 12px;
    margin-left: 0;
    margin-right: 4px;
}

@media (max-width: 810px) {
    .rtl.app-client .rs-navbar-header .rs-dropdown .rs-btn > .rs-dropdown-toggle-caret {
        top: 18px;
    }
}

@media (max-width: 810px) {
    .rtl.app-client .rs-navbar-header {
        height: auto;
    }
}

.rtl.app-client .rs-dropdown-menu .rs-dropdown-item-content > .rs-icon,
.rtl.app-client .rs-nav-item > .rs-nav-item-content > .rs-icon {
    margin-right: 0;
    margin-left: 6px;
}

.rtl.app-client .rs-dropdown-menu .rs-dropdown-item-content {
    text-align: right;
}

.rtl.app-client .rs-modal-header .rs-modal-header-close {
    left: 20px;
    right: auto;
}

.rtl.app-client .rs-notification-item-close {
    left: 8px;
    right: auto;
}

.rtl.app-client .rs-message.rs-message-has-icon .rs-message-container {
    padding-left: 20px;
    padding-right: 42px;
}

.rtl.app-client .rs-message.rs-message-has-icon.rs-message-has-title .rs-message-container {
    padding-right: 54px;
}

.rtl.app-client .rs-message .rs-message-icon-wrapper {
    left: auto;
    right: 20px;
}

.rtl.app-client .rs-modal-header {
    padding-left: 20px;
    padding-right: 0;
}
.rtl.app-client .rs-modal-footer {
    text-align: left;
}

.rtl.app-client .rs-modal-footer .rs-btn + .rs-btn {
    margin-left: 0;
    margin-right: 10px;
}

.rtl.app-client .rs-btn-sm.rs-btn-icon > .rs-icon {
    right: 0 !important;
    left: auto !important;
}

.rtl.app-client .rs-pagination {
    padding-right: 0;
}

.rtl.app-client .rs-form-inline .rs-form-group .rs-form-control-wrapper {
    margin-left: 0;
    margin-right: 12px;
}

.rtl.app-client .rs-form-inline > *,
.rtl.app-client .rs-form-inline > .rs-btn {
    margin-left: 30px;
    margin-right: 0;
}

.rtl.app-client .rs-btn-toolbar > :not(:first-child):not(.rs-btn-block) {
    margin-left: 0;
    margin-right: 5px;
}

.rtl.app-client .rhap_container {
    direction: ltr;
}

.rtl.app-client .rs-checkbox-inline {
    margin-left: 0;
    margin-right: 10px;
}

.rtl.app-client .rs-checkbox-checker {
    padding-left: 0;
    padding-right: 36px;
}

.rtl.app-client .rs-check-item .rs-checkbox-checker {
    padding: 0;
}

.rtl.app-client .rs-check-item .rs-checkbox-checker > label {
    padding: 8px 38px 8px 12px;
}

.rtl.app-client .rs-checkbox-wrapper {
    left: auto;
    right: 10px;
}

.rtl.app-client .rs-check-item .rs-checkbox-checker > label .rs-checkbox-wrapper {
    left: auto;
    right: 12px;
}

.rtl.app-client .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-backward {
    float: right;
}

.rtl.app-client .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-forward {
    float: left;
}

.rtl.app-client .rs-table-loader-icon {
    margin-left: 12px;
    margin-right: 0;
}

.rtl.app-client .rs-dropdown-open.rs-dropdown-placement-top-start > .rs-dropdown-menu,
.rtl.app-client .rs-dropdown-open.rs-dropdown-placement-bottom-start > .rs-dropdown-menu {
    left: auto;
    right: 0;
}

.rtl.app-client .rs-dropdown-open.rs-dropdown-placement-top-end > .rs-dropdown-menu,
.rtl.app-client .rs-dropdown-open.rs-dropdown-placement-bottom-end > .rs-dropdown-menu {
    left: 0;
    right: auto;
}

.rtl.app-client .rs-dropdown .rs-btn > .rs-icon {
    margin-left: 6px;
    margin-right: 0;
}

.rtl.app-client .rs-dropdown .rs-btn > .rs-dropdown-toggle-caret {
    margin-left: 0;
    margin-right: 4px;
    left: 16px;
    right: auto;
}

.rtl.app-client .rs-col-xs-1,
.rtl.app-client .rs-col-xs-2,
.rtl.app-client .rs-col-xs-3,
.rtl.app-client .rs-col-xs-4,
.rtl.app-client .rs-col-xs-5,
.rtl.app-client .rs-col-xs-6,
.rtl.app-client .rs-col-xs-7,
.rtl.app-client .rs-col-xs-8,
.rtl.app-client .rs-col-xs-9,
.rtl.app-client .rs-col-xs-10,
.rtl.app-client .rs-col-xs-11,
.rtl.app-client .rs-col-xs-12,
.rtl.app-client .rs-col-xs-13,
.rtl.app-client .rs-col-xs-14,
.rtl.app-client .rs-col-xs-15,
.rtl.app-client .rs-col-xs-16,
.rtl.app-client .rs-col-xs-17,
.rtl.app-client .rs-col-xs-18,
.rtl.app-client .rs-col-xs-19,
.rtl.app-client .rs-col-xs-20,
.rtl.app-client .rs-col-xs-21,
.rtl.app-client .rs-col-xs-22,
.rtl.app-client .rs-col-xs-23,
.rtl.app-client .rs-col-xs-24 {
    float: right;
}

.rtl.app-client .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
    padding: 7px 11px;
    padding-left: 36px;
}

.rtl.app-client .rs-picker-menu .rs-picker-search-bar::after {
    left: 24px;
    right: auto;
}

.rtl.app-client .rs-picker-menu:not(.no-rtl) {
    text-align: right;
}

.rtl.app-client .rs-picker-default:not(.no-rtl) .rs-picker-toggle,
.rtl.app-client .rs-picker-default:not(.no-rtl) .rs-picker-toggle-custom {
    padding-left: 32px;
    padding-right: 11px;
    text-align: right;
}

.rtl.app-client .rs-picker-default:not(.no-rtl) .rs-picker-toggle-caret {
    left: 12px;
    right: auto;
}

.rtl.app-client .rs-form-horizontal .rs-form-group .rs-control-label {
    float: right;
    margin-left: 12px;
    margin-right: 0;
    text-align: right !important;
}

.rtl.app-client .rs-form-horizontal .rs-form-group .rs-form-control-wrapper {
    float: right;
}

.rtl.app-client .rs-picker-toolbar-right {
    float: left;
}

.rtl.app-client .rs-calendar-header-forward::before {
    content: "";
}

.rtl.app-client .rs-calendar-header-backward::before {
    content: "";
}

.rtl.app-client .api_documentation-currentapikey {
    margin-left: 0px;
    margin-right: 40px;
}

.rtl.app-client .api_documentation_list-parameters_list_item_wrapper_item {
    margin-left: 0px;
    margin-right: 20px;
}

.rtl.app-client .api_documentation_list-parameters_list_item_wrapper_item.header {
    margin-right: 0;
}

.rtl.app-client .api_documentation-currentapikey_key_value {
    margin-left: 0px;
    margin-right: 30px;
}

.rtl.app-client .api_documentation_list-method_api_header {
    margin-left: 20px;
    margin-right: 0px;
}

.rtl.app-client .api_documentation_list .api_documentation_list-method_api_link_wrapper .api_documentation_list-method_api_link_wrapper_header {
    margin-left: 20px;
    margin-right: 0px;
}

.rtl.app-client .api_keys-list_item_icon {
    left: 5px;
    right: 0px;
}

.rtl.app-client .api_documentation-tooltip {
    margin-left: 0px;
    margin-right: 10px;
}

.rtl.app-client .api_keys-panel_button_icon {
    margin-left: 0px;
    margin-right: 10px;
}

.rtl.app-client .api_keys-panel_button_generate {
    margin-left: 0px;
    margin-right: 20px;
}

.rtl.app-client .simple_code {
    padding: 20px 40px 0 0px;
}
.rtl.app-client .rs-tree-node {
    text-align: right;
}

.rtl.app-client .rs-tree-node > .rs-tree-node-label {
    margin: 0;
    padding: 8px 12px;
    padding-right: 24px;
}

.rtl.app-client .rs-tree-node > .rs-tree-node-label::before {
    margin-left: 0;
    margin-right: -24px;
}

.rtl.app-client .rs-tree-node-expand-icon-wrapper > .rs-tree-node-expand-icon {
    padding: 8px 0;
    padding-left: 8px;
    -webkit-transform: rotate(90deg) translateX(-6px) translateY(-8px);
    transform: rotate(90deg) translateX(-6px) translateY(-8px);
    -webkit-transform-origin: 3.5px 16px;
    transform-origin: 3.5px 16px;
    margin-left: 0;
    margin-right: -2px;
}

.rtl.app-client .rs-tree-node-expand-icon-wrapper > .rs-tree-node-expand-icon::before {
    content: "";
}

.rtl.app-client .rs-tree-open > .rs-tree-node .rs-tree-node-expand-icon-wrapper > .rs-tree-node-expand-icon {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    margin-right: 0;
    margin-top: 0;
}

.rtl.app-client .rs-tree-node-expand-icon-wrapper > .rs-tree-node-expanded {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    margin-right: 0;
    margin-top: 0;
}

.rtl.app-client .rs-tree-node-expand-icon-wrapper > .rs-tree-node-custom-icon {
    width: 14px;
    line-height: 36px;
    vertical-align: middle;
}

.rtl.app-client .rs-tree-node-children > .rs-tree-children {
    display: none;
}

.rtl.app-client .rs-tree-open.rs-tree-node-children > .rs-tree-children {
    display: block;
}

.rtl.app-client .rs-tree-node-children .rs-tree-node-active > .rs-tree-node-label {
    border-left: none;
    border-right: 3px solid #20BA88;
}

.rtl.app-client .rs-tree-node-children > .rs-tree-node-active > .rs-tree-node-label {
    border-right: none;
}

.rtl.app-client .rs-tree-node {
    padding-left: 0 !important;
    padding-right: 12px !important;
}

.rtl.app-client .rs-tree-children .rs-tree-node {
    padding-right: 28px !important;
}

.rtl.app-client .rs-tree-children .rs-tree-children .rs-tree-node {
    padding-right: 44px !important;
}

.rtl.app-client .object-content {
    margin-left: 0 !important;
    margin-right: 6px !important;
}

.rtl.app-client .variable-row,
.rtl.app-client .object-key-val {
    padding: 3px 20px 3px 5px !important;
    border-left: none !important;
    border-right: 1px solid #ebebeb !important;
}

.rtl.app-client .react-json-view .collapsed-icon svg {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.rtl.app-client .rs-table {
    direction: ltr;
}

.rtl.app-client .rs-table .rs-table-row {
    direction: rtl;
}

.rtl.app-client .rs-picker-toggle-clean {
    left: 30px;
    right: auto;
}

.rtl.app-client .rs-panel-collapsible > .rs-panel-heading::before {
    right: auto;
    left: 20px;
}

.rtl.app-client .rs-table-cell-content {
    direction: ltr;
}

.rtl.app-client .rs-picker-select-menu-item {
    direction: rtl;
}