.CheckboxBadgeWrapper {
    display: inline-block;
    margin: 5px 5px;
}
.CheckboxBadgeWrapper :global(.rs-checkbox-inline),
.CheckboxBadgeWrapper :global(.rs-checkbox-checker) {
    line-height: inherit;
    margin: 0;
    padding: 0;
}

.CheckboxBadgeWrapper :global(.rs-checkbox-checker) label {
    line-height: inherit;
}

.CheckboxBadgeWrapper :global(.rs-checkbox-wrapper) {
    display: none;
}

.CheckboxBadge {
    border-radius: 15px;
    border: 1px solid #939191;
    display: inline-block;
    padding: 7px 9px;
    cursor: pointer;
}
.CheckboxBadge :global(.rs-icon-plus) {
    font-size: 10px;
    position: relative;
    top: -1px
}

:global(.rs-checkbox-checked) .CheckboxBadge {
    background: var(--color-brand2);
    border-color: var(--color-brand2);
    color: white;
}

.CheckboxBadge :global(.rs-icon-check) {
    display: none;
}

:global(.rs-checkbox-checked) .CheckboxBadge :global(.rs-icon-plus) {
    display: none;
}

:global(.rs-checkbox-checked) .CheckboxBadge :global(.rs-icon-check) {
    display: inline-block;
}