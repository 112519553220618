a.ranges_numbers_table_link {
    color: #1b1e21;
}
a.ranges_numbers_table_link:hover {
    text-decoration: none;
}
.ranges_numbers_table_link:active {
    text-decoration: none !important;
    color: #1b1e21 !important;
}
.ranges_numbers_table_link:focus {
    text-decoration: none !important;
    color: #1b1e21 !important;
}