:root{
    --color-violet: #8256C8;
    --color-violet-dark: #5f2bb3;
    --color-green: #20BA88;
    --color-green-light: #20CA88;



    --color-hover: #5f2bb3;
    --color-grey: #939191;
    --color-bg: #E5E5E5;
    --color-text: #575757;
    --color-light: #FFFFFF;
    --color-dark: #000000;
    --color-disabled: #939191;

    --color-success: #4ccc37;
    --color-info: #299AF3;
    --color-warning: #FFB300;
    --color-error: #F8432F;
    --color-error2: #F44336;
    --color-status-badge: #00ffa1;
    --color-row-active: #F0E7FF;
    --activate-bg: #1e90ff;

    --color-main: var(--color-violet);
    --color-main-hover: var(--color-violet-dark);
    --color-brand1: var(--color-main);
    --color-brand2: var(--color-green);
    --color-primary: var(--color-brand1);
    --color-primary-hover: var(--color-main-hover);
    --color-secondary: var(--color-brand2);
    --color-secondary-hover: var(--color-green-light);
}

:root .app-client {
    --color-main: var(--color-violet);
    --color-main-hover: var(--color-violet-dark);
    --color-brand1: var(--color-green);
    --color-brand2: var(--color-main);
    --color-primary: var(--color-brand1);
    --color-primary-hover: #20CA88;
    --color-secondary: var(--color-brand2);
    --color-secondary-hover: var(--color-main-hover);
    --color-row-active: #d9d9f2;
}

.color-primary {
    color: var(--color-primary) !important;
}

.color-secondary {
    color: var(--color-primary) !important;
}