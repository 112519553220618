/* rsuite form custom styles */
.rs-form-control-wrapper, .rs-form-control-wrapper > .rs-input, .rs-form-control-wrapper > .rs-input-number, .rs-form-control-wrapper > .rs-picker-toggle-wrapper {
    width: 100%;
}

.rs-form-vertical .rs-form-group .rs-input-group {
    width: 100%;
}

.rs-form-inline .rs-form-group .rs-form-control-wrapper {
    width: auto;
}

.rs-input,
.rs-form-control-wrapper > .rs-input,
.rs-form-control-wrapper > .rs-input-group,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle,
.rs-input-group:not(.rs-input-group-inside):not(.rs-input-group-disabled) {
    border: 1px solid #e5e5ea;
}

.app-client .rs-input,
.app-client .rs-form-control-wrapper > .rs-input,
.app-client .rs-form-control-wrapper > .rs-input-group,
.app-client .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle,
.app-client .rs-input-group:not(.rs-input-group-inside):not(.rs-input-group-disabled) {
    border: 1px solid var(--color-grey);
}

.rs-input:hover, .rs-input:focus,
.rs-form-control-wrapper > .rs-input:hover,
.rs-form-control-wrapper > .rs-input:focus,
.rs-form-control-wrapper > .rs-input-group:hover,
.rs-form-control-wrapper > .rs-input-group:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.rs-input-group:not(.rs-input-group-inside):not(.rs-input-group-disabled):hover,
.rs-input-group:not(.rs-input-group-inside):not(.rs-input-group-disabled):focus {
    border-color: #1675e0;
}

.app-client .rs-input:hover, .app-client .rs-input:focus,
.app-client .rs-form-control-wrapper > .rs-input:hover,
.app-client .rs-form-control-wrapper > .rs-input:focus,
.app-client .rs-form-control-wrapper > .rs-input-group:hover,
.app-client .rs-form-control-wrapper > .rs-input-group:focus,
.app-client .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
.app-client .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.app-client .rs-input-group:not(.rs-input-group-inside):not(.rs-input-group-disabled):hover,
.app-client .rs-input-group:not(.rs-input-group-inside):not(.rs-input-group-disabled):focus {
    border-color: var(--color-brand2);
}

.rs-input::placeholder,
.rs-form-control-wrapper > .rs-input::placeholder,
.rs-form-control-wrapper > .rs-input-group::placeholder,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle::placeholder,
.rs-input-group:not(.rs-input-group-inside):not(.rs-input-group-disabled)::placeholder {
    opacity: 0.75;
}

.rs-input-group:not(.rs-input-group-inside):not(.rs-input-group-disabled) .rs-input {
    border: none;
}

.app-client .rs-input-number .rs-input {
    background-color: transparent;
}

textarea.rs-input {
    resize: none;
    min-width: 0;
    min-height: 76px;
    max-width: 100%;
    max-height: 200px;
}

.rs-picker-default .rs-picker-toggle .rs-picker-toggle-value, .rs-picker-default .rs-picker-toggle .rs-picker-toggle-value b {
    color: #585858;
}
.app-client .rs-picker-default .rs-picker-toggle .rs-picker-toggle-value, .app-client .rs-picker-default .rs-picker-toggle .rs-picker-toggle-value b {
    color: var(--color-brand2);
}
.rs-picker-default .rs-picker-toggle .rs-picker-toggle-caret {
    color: var(--color-brand2);
}
.app-client .rs-picker-default .rs-picker-toggle .rs-picker-toggle-caret {
    color: var(--color-brand1);
}
.rs-picker-default .rs-picker-toggle:focus .rs-picker-toggle-caret {
    color: var(--color-brand2);
}

.rs-checkbox-wrapper .rs-checkbox-inner:before {
    background: #C4C4C4;
    border-radius: 5px;
    border: 1px solid #C4C4C4;
}
.rs-table-cell-content .rs-checkbox-wrapper .rs-checkbox-inner:before, .app-client .rs-checkbox-wrapper .rs-checkbox-inner:before {
    border: 1px solid #d9d9d9;
    background-color: transparent;
    border-radius: 3px;
}
.rs-checkbox-wrapper.rs-checkbox-checked .rs-checkbox-inner:before {
    background-color: var(--color-brand2);
    border-color: var(--color-brand2);
}

.rs-radio-checker .rs-radio-wrapper:before {
    border: 1px solid var(--color-brand2);
}
.app-client .rs-radio-checker .rs-radio-wrapper:before {
    background-color: transparent;
    border-color: var(--color-brand1);
}
.rs-radio-checker .rs-radio-wrapper .rs-radio-inner:before {
    background: #C4C4C4;
    border: 1px solid #C4C4C4;
}
.app-client .rs-radio-checker .rs-radio-wrapper .rs-radio-inner:before {
    background-color: transparent;
    border-color: #d9d9d9;
}
.rs-radio-checked .rs-radio-checker .rs-radio-wrapper .rs-radio-inner:before {
    border-color: var(--color-brand2);
    background-color: var(--color-brand2);
}
.app-client .rs-radio-checked .rs-radio-checker .rs-radio-wrapper .rs-radio-inner:before {
    border-color: var(--color-brand1);
    background-color: var(--color-brand1);
}
.rs-radio-checker .rs-radio-wrapper:hover [type=radio]:focus ~ .rs-radio-inner::before,
.rs-radio-checker .rs-radio-wrapper [type=radio]:focus ~ .rs-radio-inner::before, .rs-radio-checked .rs-radio-checker .rs-radio-wrapper [type=radio]:focus ~ .rs-radio-inner::before {
    -webkit-box-shadow: 0 0 0 2px rgba(52, 152, 255, 0.3);
    box-shadow: 0 0 0 2px rgba(52, 152, 255, 0.3);
}

.rs-picker-menu .rs-calendar-table-cell.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    border: 1px solid #3498ff;
    padding: 4px;
}
.app-client .rs-picker-menu .rs-calendar-table-cell.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    border: 1px solid var(--color-brand2);
}
.rs-picker-menu .rs-calendar-table-cell.rs-calendar-table-cell-in-range:before {
    background: rgba(204, 233, 255, 0.5);
}
.app-client .rs-picker-menu .rs-calendar-table-cell.rs-calendar-table-cell-in-range:before {
    background: #e6e4f6;
}
.rs-picker-menu .rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
.rs-picker-menu .rs-picker-toolbar-right-btn-ok {
    background: #3498ff;
    border-color: #3498ff;
}
.app-client .rs-picker-menu .rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
.app-client .rs-picker-menu .rs-picker-toolbar-right-btn-ok {
    background: var(--color-brand2);
    border-color: var(--color-brand2);
}
.rs-picker-menu .rs-picker-toolbar-option {
    color: #085cc9;
}
.app-client .rs-picker-menu .rs-picker-toolbar-option {
    color: var(--color-brand2);
}
@media only screen and (max-width: 600px) {
    .rs-picker-menu .rs-picker-daterange-menu .rs-calendar:first-child {
        border: none;
    }
}

.rs-form-inline > *,
.rs-form-inline > .rs-btn,
.rs-form-inline > .rs-control-label {
    margin-right: 30px;
}