.rs-modal .rs-modal-body {
    max-height: 650px !important;
}

@media (max-width: 768px) {
    div.rs-modal.rs-modal-sm[role="dialog"] {
        margin: 0 !important;
        width: 100% !important;
        height: 100% !important;
    }
    .rs-modal .rs-modal-body {
        width: 100%  !important;
        max-height: 90% !important;
        padding-top: 10px;
    }
    .rs-modal .rs-modal-content {
        height: 100% !important;
        border-radius: 0;
    }
    .rs-modal .rs-modal-dialog {
        height: 100% !important;
    }
    .rs-modal .rs-modal-header {
        top: 10px;
    }
    .rs-modal .rs-modal-title, .rs-modal .rs-modal-header-close {
        color: #575757 !important;
        z-index: 2;
    }
}