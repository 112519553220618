.tab-general-custom-field.has-changed {
    border: 1px solid #70a751;
    box-shadow: inset 0 0 4px #70a751;
}

.tab-general-select-field {
    width: 100%;
}

.tab-general-select-field.has-changed .rs-picker-toggle {
    border: 1px solid #70a751;
    box-shadow: inset 0 0 4px #70a751;
}
.tab-general-radio-field.has-changed label {
    color: #70a751;
}
.tab-general-addable {
    margin-bottom: 0;
    padding: 5px 10px 5px 10px !important;
}
.tab-general-addable div.rs-form-group {
    margin: 0 !important;
    padding: 0 10px 0 0 !important;
}
.tab-general-addable div.rs-form-control-wrapper, .tab-general-addable div.minWidth-wrapper {
    margin: 0 !important;
}
.tab-general-addable.rs-form-inline > *, .tab-general-addable.rs-form-inline > .rs-btn {
    margin: 0 !important;
    padding: 0 !important;
}
.tab-general-addable.rs-form-inline .rs-btn.button-minus {
    margin: 6px 0 0 0 !important;
}
.tab-general-addable .remove-button, .tab-general-addable .rs-form-inline {
    margin-bottom: 0 !important;
    margin-right: 0 !important;
    margin-left: 5px;
    margin-top: 5px;
}
.rs-form.tab-general-addable .rs-form-group:nth-last-child(2) {
    margin-right: 0 !important;
}
.tab-general-addable .rs-help-block {
    min-height: 0;
    margin-bottom: 0 !important;
}
.tab-general-addable.has-changed {
    box-shadow: 0 0 6px 2px #70a751;
    border-radius: 15px;
}
