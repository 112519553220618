.label {
    width: inherit;
}

.inline {
    align-items: center;
    display: flex;
    margin-left: -4px;
    margin-right: -4px;
}

.inline .label {
    flex-shrink: 0;
    margin-bottom: 0;
    margin-left: 4px;
    margin-right: 4px;
}

.inline .field {
    flex-grow: 1;
    margin-left: 4px;
    margin-right: 4px;
}

.field > div {
    vertical-align: top;
}

.input {
    width: 100%;
}