.outsideContainer {
  width: 270px;
  display: inline-block;
  margin-bottom: 0px;
}

.outsideContainer input {
  width: 270px;
}

.dateTimeRangePickerContainer {
    position: absolute;
    /* border: 1px solid rgba(34, 36, 38, 0.15); */
    border-radius: 0.28571429rem;
    /* z-index: 99999 !important; */

  }
  
  .dateTimeRangePickerDisplay {
    display: inline-block;
  }

  .dateTimeRangePickerDisplay input {
    /* display: none; */
    height: 0px;
    width: 250px;
    padding: 0px;
    border: 0px;
  }
  
  .rdtDay.inSelectingRange {
    background-color: rgba(66, 139, 202, 0.4);
  }
  