/* rsuite table custom styles */
.app-client .rs-table-scrollbar-handle {
    background-color: #c5c6c7;
}

.app-client .rs-table-cell-group-fixed-right {
    margin-left: 0;
}

.rs-table-row.expired .rs-table-cell {
    background-color: rgba(87, 87, 87, 0.2);
}